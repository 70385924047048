import { SettingsService } from 'src/app/modules/core/services/settings.service';
import { UnsubscribeOnDestroy } from 'src/app/modules/shared/utilities/unsubscribe-on-destroy';
import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class FunctionTimeoutService extends UnsubscribeOnDestroy {
	private timeout!: number;
	
	constructor(settingsService: SettingsService) {
		super();
	
		settingsService.getSettings().pipe(
			this.takeUntilDestroyed()
		).subscribe(settings => {
			// Convert timeout to ms
			this.timeout = settings.functionTimeoutInSeconds * 1000;
		});
	}

	promiseWithTimeout<T>(promise: Promise<T>, timeoutError = new Error('Promise timed out')): Promise<T> {
		// create a promise that rejects in milliseconds
		const timeout = new Promise<never>((_, reject) => {
			setTimeout(() => {
				reject(timeoutError);
			}, this.timeout);
		});

		// returns a race between timeout and the passed promise
		return Promise.race<T>([promise, timeout]);
	}
}